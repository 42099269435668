<template>
  <header id="header" style="background-color: #FFF">
    <div class="container">
      <nav class="navbar navbar-expand-md">
        <div style="display: flex; align-items: center; height: 30px">
          <router-link class="navbar-brand" to="/">
            <!--suppress HtmlUnknownTarget -->
            <img src="@/assets/mcc-logo-4.png" alt="MyCloudCoin">
          </router-link>
        </div>
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item"><router-link class="nav-link" to="/">Home</router-link></li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>
<script rel="script">

  export default {
    name: 'Header.vue'
  }

</script>
<!--suppress CssUnusedSymbol -->
<style rel="stylesheet" scoped>

  header {
    background-color: transparent;
    padding-bottom: .6rem;
    padding-top: .6rem;
    border-radius: 0;
    margin-bottom: 0;
    width: 100%;
    z-index: 1;
    border-bottom: solid 1px #E8E8E8;
    box-shadow: 0 10px 15px 0 rgba(150,150,150,.2);
    position: fixed;
    top: 0;
  }

  header.expanded {
    padding-bottom: .8rem;
    padding-top: .8rem;
  }

  header img {
    height: 45px;
  }

  header.expanded img {
    height: 60px;
  }

  header .navbar {
    padding-left: 0;
    padding-right: 0;
  }

  header .logo {
    display: inline-block;
    vertical-align: middle;
  }

  header .navbar .nav-item a {
    font-size: 1em;
    color: #000;
    position: relative;
    font-weight: 600;
  }

  header .navbar .nav-item a:before {
    content: "";
    position: absolute;
    width: calc(100% - 20px);
    height: 1px;
    bottom: 0;
    left: 10px;
    background: #FFF;
    visibility: hidden;
    border-radius: 0;
    transform: scaleX(0);
    transition: .15s ease-in-out;
    -webkit-transform: scaleX(0);
    -webkit-transition: .15s ease-in-out;
  }

  header .navbar .nav-item:hover a:before {
    visibility: visible;
    transform: scaleX(1);
  }

  header .navbar .nav-item:not(.region-selector) {
    color: #fff;
    font-weight: 300;
    font-size: 1em;
    margin-left: 0.8em;
    margin-right: 0.8em;
  }

  @media only screen and (max-width: 920px){
      header.expanded{
          padding-bottom: 20px;
      }
  }

</style>