<template>
    <FormCadastro/>
</template>
<script>

import FormCadastro from './Cadastro/FormCadastro';

export default{
    name: 'MainFormCadastro.vue',
    components: {
      FormCadastro
    },
    
}
</script>
