<template>
  <div class="home m-0">
    <div id="learn-more-layout" class="container"
         :style="{'background-image': 'url(assets/img/biscoint-landing-woman-7.png)'}">
      <div class="call-to-action row">
        <h2 class="col">
          <span class="call-biscoint">
            <span style="color: #BC0000">My</span>
            <span style="color: #E0E0E0">Cloud</span>
            <span style="color: #BC0000">Coin</span>
          </span>
          <br>como o próprio nome já diz<br>é a sua crypto moeda na nuvem.
        </h2>
        <div class="col m-450">
          <form class="" action="cadastro">
            <h6>Realize seu cadastro para começar:</h6>
            <input  name="email" placeholder="E-mail" type="email" class="inputemail form-control-lg form-control" value="" required>
            <button type="submit" class="btn">Criar Conta</button>
          </form>
          <div class="has-account">
            <!-- <a href="/sign-in">Já tem uma conta? <span class="has-account-enter">Entrar {{email}}</span></a> -->
          </div>
        </div>
      </div>
    </div>
    <div id="learnmore-body">
      <div class="learnmore-section">
        <section>
          <div class="container">
            <div class="row align-children">
              <div class="col-md-5 col-sm-3 text-center mb-xs-24">
                <img class="img-responsive" alt="Uma conta" src="@/assets/oneaccount.svg">
              </div>
              <div class="col-md-6 col-md-offset-1 col-sm-7 text-lg-start text-center col-sm-offset-1">
                <div class="">
                  <h3>Uma conta, várias possibilidades</h3>
                  <div class="mb32">
                    <p>
                      Credite reais "R$", e tenha seus NFTs - MyCloudCoin, que a qualquer momento podem ser trocados
                      por mercadorias ou simplesmente sacados por você para sua conta bancária em qualquer banco.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="clearfix"></div>
      </div>
      <div class="learnmore-section">
        <!--suppress XmlDuplicatedId -->
        <div id="shapely_home_parallax-3" class="widget shapely_home_parallax">
          <section class="">
            <div class="container">
              <div class="row align-children">
                <div class="col-md-5 col-sm-3 text-center mb-xs-24">
                  <img class="img-responsive" alt="Prazo" src="@/assets/clock_money.svg">
                </div>
                <div class="col-md-6 col-md-offset-1 col-sm-7 text-lg-start text-center col-sm-offset-1">
                  <div class="">
                    <h3>Conversão instantânea</h3>
                    <div class="mb32">
                      <p>
                        Creditando reais, você garante seus NFTs - MyCloudCoin no momento do crédito, nosso NFT é
                        lastreado na StableCoin - USDT, que basicamente tem o valor do dólar turismo.<br>Assim você
                        trava seu cambio e não perde nenhuma oportunidade no mercado, mesmo nos finais de semana ou
                        feriados.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="learnmore-section">
        <!--suppress XmlDuplicatedId -->
        <div id="shapely_home_parallax-3" class="widget shapely_home_parallax">
          <section class="">
            <div class="container">
              <div class="row align-children">
                <div class="col-md-5 col-sm-3 text-center mb-xs-24">
                  <img class="img-responsive" alt="Limite" src="@/assets/handshake_deal.svg">
                </div>
                <div class="col-md-6 col-md-offset-1 col-sm-7 text-lg-start text-center col-sm-offset-1">
                  <div class="">
                    <h3>Limites operacionais</h3>
                    <div class="mb32">
                      <p>
                        Aceitamos transferências do tipo <strong>TED e Pix feitas a partir de qualquer banco.</strong>
                        Basta ter uma conta verificada.
                        <a class="yellowlink" href="/fees-limits" style="display: block;">
                          Saiba mais sobre os limites.
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="clearfix"></div>
        </div>
      </div>
      <hr>
      <h1 class="howitworks">Como funciona</h1>
      <div class="learnmore-section">
        <!--suppress XmlDuplicatedId -->
        <div id="shapely_home_parallax-3" class="widget shapely_home_parallax">
          <section class="">
            <div class="container">
              <div class="row align-children">
                <div class="col-md-5 col-sm-3 text-center mb-xs-24">
                  <img class="img-responsive" alt="Cadastre-se" src="@/assets/id_register.svg">
                </div>
                <div class="col-md-6 col-md-offset-1 col-sm-7 text-lg-start text-center col-sm-offset-1">
                  <div class="">
                    <h3>Cadastro</h3>
                    <div class="mb32">
                      <p>
                        Você faz um registro rápido, acessa nosso contrato e assina ele digitalmente, pelo seu celular
                        mesmo, e pronto, a aprovação da conta ocorre quase que instantânea e, em alguns instantes você
                        já pode comprar e aproveitar sua MyCloudCoin.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="clearfix">
          </div>
        </div>
      </div>
      <div class="learnmore-section">
        <!--suppress XmlDuplicatedId -->
        <div id="shapely_home_parallax-3" class="widget shapely_home_parallax">
          <section class="">
            <div class="container">
              <div class="row align-children">
                <div class="col-md-5 col-sm-3 text-center mb-xs-24">
                  <img class="img-responsive" alt="Compre" src="@/assets/currency_exchange.svg">
                </div>
                <div class="col-md-6 col-md-offset-1 col-sm-7 text-lg-start text-center col-sm-offset-1">
                  <div class="">
                    <h3>Compra</h3>
                    <div class="mb32">
                      <p>
                        Credite Reais (R$), confirme sua compra e pronto. Seus NFTs - MyCloudCoin já estão comprados
                        e você já pode comprar mercadorias, transferir para quem você quiser ou, simplesmente resgatar
                        eles em reais, creditando em sua conta bancária. A escolha é sempre sua.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="learnmore-section">
        <!--suppress XmlDuplicatedId -->
        <div id="shapely_home_parallax-3" class="widget shapely_home_parallax">
          <section class="">
            <div class="container">
              <div class="row align-children">
                <div class="col-md-5 col-sm-3 text-center mb-xs-24">
                  <img class="img-responsive" alt="Pague" src="@/assets/money_bag.svg">
                </div>
                <div class="col-md-6 col-md-offset-1 col-sm-7 text-lg-start text-center col-sm-offset-1">
                  <div class="">
                    <h3>Pagamento</h3>
                    <div class="mb32">
                      <p>
                        Quando você anexa o comprovante de transferência, a gente confere, e fim! Você pode manter
                        seus NFTS - MyCloudCoin em sua carteira ou trocá-los por mercadorias.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="clearfix"></div>
        </div>
      </div>
      <hr>
      <h1>Está pronto?</h1>
      <div class="call-to-action-end row">
        <div class="text-center col">
          <p class="inform">Informe seu e-mail para começar.</p>
          <div class="lead">
            <form class="justify-content-center email-form form-inline" action="cadastro">
              <input id="email" name="email" placeholder="e-mail" type="email" class="inputemail form-control-lg form-control" value="" required>
              <button type="submit" class="btn btn-biscoint">Começar</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script rel="script">



  export default {
    name: "Home.vue",
  }

</script>
<style rel="stylesheet" scoped>

  .home {
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 0 auto;
  }

  .home #learn-more-layout {
    overflow-x: hidden;
    overflow-y: hidden;
    /*noinspection CssUnknownTarget*/
    background-image: url('/assets/img/biscoint-landing-woman-1.png');
    background-color: #f3f3f3;
    background-repeat: no-repeat;
    background-position-y: center;
    max-width: 100%;
    height: 670px;
    min-height: 100vh;
    background-size: 111%!important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .home #learn-more-layout .call-to-action {
    text-align: center;
    margin-bottom: 3%;
    position: absolute;
    top: 80px;
  }

  .home #learn-more-layout .call-to-action h2 {
    color: #fff;
    font-weight: 900;
    letter-spacing: -1px;
    padding-right: 244px;
    margin-bottom: 30px;
    font-size: 24px;
  }

  .home #learn-more-layout .call-to-action .call-biscoint {
    font-size: 65px;
  }

  .home #learn-more-layout .call-to-action h6 {
    color: #fff;
    font-size: .9rem;
    text-align: left;
  }

  .home #learn-more-layout .call-to-action input {
    border: none;
    display: block;
    margin-top: 10px;
    padding: 14px;
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
  }

  .home #learn-more-layout .call-to-action .btn {
    background: #B10000;
    padding: 12px 50px;
    margin-top: 10px;
    width: 100%;
    letter-spacing: 0;
    font-weight: 900;
    border-radius: 4px;
    color: #ECECEC;
  }

  .home #learn-more-layout .call-to-action .btn:focus,
  .home #learn-more-layout .call-to-action .btn:hover {
    color: #ECECEC;
    background-color: #C11010;
    border-color: #C21111;
    box-shadow: 0 0 0 0.2rem rgb(194 17 17 / 50%);
  }

  .home #learn-more-layout .call-to-action .has-account {
    margin-top: 15px;
    cursor: pointer;
  }

  .home #learn-more-layout .call-to-action .has-account a {
    color: #ECECEC;
    font-weight: 400;
    font-size: .8rem;
  }

  .home #learn-more-layout .call-to-action .has-account a .has-account-enter {
    font-weight: 700;
  }

  .home #learnmore-body {
    background: #f3f3f3;
    padding: 2%;
  }

  .home #learnmore-body .learnmore-section {
    margin: 2% 2% 4%;
    border-radius: 8px;
    font-family: Open Sans,sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    box-sizing: border-box;
    background: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 24px;
    color: #495057;
    overflow-x: hidden;
    font-weight: 400;
  }

  .home #learnmore-body .learnmore-section section {
    display: block;
    padding: 96px 0;
    position: relative;
    overflow: hidden;
  }

  .home #learnmore-body .learnmore-section .container {
    position: relative;
  }

  #app #learnmore-body .learnmore-section .align-children {
    display: -moz-flex;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .home #learnmore-body .learnmore-section .widget .text-center .img-responsive {
    margin-left: auto;
    margin-right: auto;
  }

  .home #learnmore-body img {
    height: 200px;
  }

  .home #learnmore-body .learnmore-section h3 {
    clear: both;
    margin-bottom: 24px;
    margin-top: 0;
    padding: 0;
    font-family: inherit;
    font-weight: 400;
    color: #0e1015;
    font-size: 30px;
    line-height: 33px;
  }

  .home #learnmore-body .learnmore-section .mb32 {
    margin-bottom: 32px;
  }

  .home #learnmore-body .learnmore-section p {
    margin-bottom: 24px;
    margin-top: 0;
    padding: 0;
    font-weight: 400;
  }

  .home #learnmore-body a.yellowlink {
    color: #f3c011;
    font-weight: 700;
    text-decoration: none;
  }

  .home #learnmore-body hr {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .home hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
  }

  .home #learnmore-body .howitworks {
    text-align: center;
    letter-spacing: -1px;
  }

  .home #learnmore-body h1 {
    text-align: center;
    font-size: 64px;
    color: #666;
  }

  .home #learnmore-body .call-to-action-end {
    margin: 80px 0;
  }

  .home p {
    margin-top: 0;
    margin-bottom: 1rem;
    color: #666;
  }

  .home .lead {
    font-size: 1.25rem;
    font-weight: 300;
  }

  .home .form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .home #learnmore-body .email-form {
    margin-bottom: 45px;
  }

  .home #learnmore-body input#email {
    width: 400px;
    font-size: 2rem;
  }

  .home #learnmore-body input#email::placeholder {
    color: #CCC;
  }

  .home #learnmore-body .btn {
    font-size: 25px;
    height: -webkit-calc(2em + 1rem);
    height: calc(2em + 1rem);
  }

  .home #learnmore-body .btn-biscoint {
    color: #fff;
    letter-spacing: -1px;
    font-weight: 600;
    padding-left: 24px;
    padding-right: 24px;
  }

  .home .btn-biscoint {
    color: #212529;
    background-color: #f3c011;
    border-color: #f3c011;
  }

  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #a6a6a6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem hsl(0deg 0% 40% / 25%);
  }

  .m-450{
      min-width: 450px
  }

  @media only screen and (max-width: 920px){
      .home #learn-more-layout .call-to-action {
          left: 10px;
          width: 100%;
      }

      .home #learn-more-layout .call-to-action h2 {
          padding-right: 0;
      }

      .home #learn-more-layout{
          background-size: cover !important;
          min-height: calc(100vh - 80px);
      }

      .m-450{
          min-width: auto;
          margin-top: 50px;
      }

      .home #learnmore-body input#email {
          width: 400px;
          font-size: 1.2rem;
      }

      .home #learnmore-body .btn{
          font-size: 1rem;
          margin-top: 10px;
      }

      .home #learn-more-layout .call-to-action .call-biscoint {
          font-size: 14vw;
      }

      .home #learnmore-body .learnmore-section section{
          padding: 20px 0;
      }
      /*Tablets [601px -> 1200px]*/
  }

</style>