<template>
    <Header/>
    <router-view/>
    <Footer/>
</template>
<script ref="script">

import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
    name: 'App.vue',
    components: {
        Header,
        Footer
    },
    watch: {
        $route() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant',
            });
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    mounted() {
        this.headerConfig();
    },
    methods: {
        headerConfig() {
            let el = document.getElementById('header');
            if (window.scrollY > 700)
                el.classList.remove("expanded");
            else el.classList.add("expanded");
        },
        handleScroll() {
            this.headerConfig();
        },
    }
}

</script>
<style>

body.vue-body {
    font-family: "Open Sans", sans-serif;
}

#app {
    padding-top: 80px;
}

</style>