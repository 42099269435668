const routes = [
    {
        path: '/',
        name: 'index',
        component: Home,
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy
    },
    {
        path: '/support',
        name: 'support',
        component: Support
    },
    {
        path: '/cadastro',
        name: 'cadastro',
        component: Cadastro
    }
]

import Home from '../views/Home';
import Terms from '../views/Terms';
import PrivacyPolicy from '../views/PrivacyPolicy';
import Support from '../views/Support';
import Cadastro from '../components/MainFormCadastro';

export default routes;