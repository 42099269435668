import { createApp } from "vue";
import App from './App.vue';
import BootstrapVue3 from "bootstrap-vue-3";
import router from './router';
import ApiService from './services/api.service';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

if (process.env.NODE_ENV === 'production')
    ApiService.setBaseUrl('https://www.mycloudcoin.com.br/backend');
else ApiService.setBaseUrl('http://localhost/cloudcrm/mycloudcoin/mycloudcoin.website.php/public');

console.log(process.env.NODE_ENV);

const app = createApp(App);

app.use(BootstrapVue3);
app.use(router);
app.mount('#app')