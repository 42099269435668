<template>
  <section class="mt-5 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-xxl-8 col-xl-10 col-lg-10 col-md-10 mx-auto">
          <div class="card login">
            <div class="text-center pt-4">
              <h3 class="">
                <b>Faça seu cadastro</b> <b style="color: red">My</b><b>Cloud</b
                ><b style="color: red">Coin</b>.
              </h3>
            </div>
            <div class="px-4 py-3 py-lg-4">
              <div>
                <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                  <div class="row col">
                    <div class="col-md-6 col-12">
                      <b-form-group>
                        <label for="" class="fw-bold"
                          >Nome Completo: <b style="color: red">*</b></label
                        >
                        <b-form-input
                          id="nome"
                          v-model="form.nome"
                          type="text"
                          placeholder="Nome Completo"
                          :class="{ 'is-invalid': v$.form.nome.$error }"
                        ></b-form-input>
                        <div class="invalid-feedack">
                          <span v-if="v$.form.nome.$error"
                            >Campo obrigatório deve ter entre 3 e 60
                            caracteres.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6 col-12">
                      <b-form-group>
                        <label for="" class="fw-bold"
                          >Data Nascimento: <b style="color: red">*</b></label
                        >
                        <b-form-input
                          id="dataNascimento"
                          :class="{
                            'is-invalid': v$.form.dataNascimento.$error,
                          }"
                          v-model="form.dataNascimento"
                          type="date"
                        ></b-form-input>
                        <div class="invalid-feedack">
                          <span v-if="v$.form.dataNascimento.$error"
                            >Campo data nascimento obrigatório.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row col">
                    <div class="col-md-6 col-12">
                      <b-form-group>
                        <label for="" class="fw-bold"
                          >CPF/CNPJ: <b style="color: red">*</b></label
                        >
                        <b-form-input
                          id="cpf"
                          v-model="form.cpf_cnpj"
                          :class="{
                            'is-invalid':
                              v$.form.cpf_cnpj.$error ||
                              (!v$.form.cpf_cnpj.$error && cpf_cnpf),
                          }"
                          type="text"
                          placeholder="Digite um CPF ou CNPJ válido"
                          v-maska="['###.###.###-##', '##.###.###/####-##']"
                          @blur="searchCPF_CNPJ"
                          :masked="false"
                        ></b-form-input>
                        <div class="invalid-feedack">
                          <span
                            v-if="
                              v$.form.cpf_cnpj.$error ||
                              (!v$.form.cpf_cnpj.$error && cpf_cnpf)
                            "
                          >
                            CPF/CNPJ inválido.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6 col-12">
                      <b-form-group>
                        <label for="" class="fw-bold"
                          >Nome da Mãe: <b style="color: red">*</b></label
                        >
                        <b-form-input
                          id="nomeMae"
                          v-model="form.nomeMae"
                          :class="{ 'is-invalid': v$.form.nomeMae.$error }"
                          type="text"
                          placeholder="Nome da Mãe"
                          required
                        ></b-form-input>
                        <div class="invalid-feedack">
                          <span v-if="v$.form.nomeMae.$error"
                            >Campo obrigatório deve ter entre 3 e 60
                            caracteres.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row col">
                    <div class="col-md-4 col-12">
                      <b-form-group>
                        <label for="" class="fw-bold"
                          >CEP: <b style="color: red">*</b></label
                        >
                        <b-form-input
                          id="cep"
                          v-model="form.cep"
                          :class="{
                            'is-invalid': meuCep || v$.form.cep.$error,
                          }"
                          type="text"
                          placeholder="Digite um CEP válido"
                          v-maska="'#####-###'"
                          @blur="searchCep"
                          required
                        ></b-form-input>
                        <div class="invalid-feedack">
                          <span v-if="meuCep || v$.form.cep.$error"
                            >CEP inválido.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6 col-12">
                      <b-form-group>
                        <label for="" class="fw-bold"
                          >Endereço: <b style="color: red">*</b></label
                        >
                        <b-form-input
                          id="endereco"
                          v-model="form.endereco"
                          :class="{ 'is-invalid': v$.form.endereco.$error }"
                          type="text"
                          placeholder="Endereço"
                          required
                        ></b-form-input>
                        <div class="invalid-feedack">
                          <span v-if="v$.form.endereco.$error"
                            >Campo endereço é obrigatório.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-md-2 col-12">
                      <b-form-group>
                        <label for="" class="fw-bold"
                          >Número: <b style="color: red">*</b></label
                        >
                        <b-form-input
                          id="numero"
                          v-model="form.numero"
                          :class="{ 'is-invalid': v$.form.numero.$error }"
                          type="text"
                          placeholder="Número"
                          v-maska="'########'"
                          required
                        ></b-form-input>
                        <div class="invalid-feedack">
                          <span v-if="v$.form.numero.$error"
                            >Campo número é obrigatório.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row col">
                    <div class="col-md-4 col-12">
                      <b-form-group>
                        <label for="" class="fw-bold">Complemento:</label>
                        <b-form-input
                          id="complemento"
                          v-model="form.complemento"
                          type="text"
                          placeholder="Complemento"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6 col-8">
                      <b-form-group>
                        <label for="" class="fw-bold">Município:</label>
                        <b-form-input
                          id="municipio"
                          v-model="form.municipio"
                          type="text"
                          placeholder="Município"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-2 col-4">
                      <b-form-group>
                        <label for="" class="fw-bold">UF:</label>
                        <b-form-input
                          id="uf"
                          v-model="form.uf"
                          type="text"
                          placeholder="UF"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row col">
                    <div class="col-md-6 col-12">
                      <b-form-group>
                        <label for="" class="fw-bold">Contato:</label>
                        <b-form-input
                          id="contato"
                          v-model="form.contato"
                          type="text"
                          placeholder="(XX) XXXXX-XXXX"
                          v-maska="['(##) ####-####', '(##) #####-####']"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6 col-12">
                      <b-form-group>
                        <label for="" class="fw-bold"
                          >E-mail: <b style="color: red">*</b></label
                        >
                        <b-form-input
                          id="email"
                          v-model="form.email"
                          :class="{ 'is-invalid': v$.form.email.$error }"
                          type="email"
                          placeholder="meucontato@contato.com"
                          required
                        ></b-form-input>
                        <div class="invalid-feedack">
                          <span v-if="v$.form.email.$error"
                            >E-mail inválido.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                    <div>
                      <vue-recaptcha
                        id="g-recaptcha"
                        :sitekey="'6Lf_P58eAAAAAM16Upi_8iW3Q0rgoOS2TQJIwKDr'"
                        @verify="verifyMethod"
                        @expired="expiredMethod"
                      ></vue-recaptcha>
                      <div class="invalid-feedack">
                        <span v-if="msgRecaptcha">Campo obrigatório.</span>
                      </div>
                    </div>
                    <br />
                  </div>
                  <div class="row mt-2 mt-md-0">
                    <div class="text-end mt-sm-2">
                      <b-button type="button" @click="onSubmit"
                        >CADASTRAR</b-button
                      >
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
            <div id="loadingDiv" v-if="spinner">
              <div>
                <div class="loader"></div>
                <p class="paragrafo">Enviando...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script rel="script">
import Helper from "@/components/helper";
import { maska } from "maska";
import { Notyf } from "notyf";
import { VueRecaptcha } from "vue-recaptcha";
import "notyf/notyf.min.css";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, maxLength } from "@vuelidate/validators";
import Cadastro from "@/services/Cadastro";

const notyf = new Notyf();
export default {
  name: "FormCadastro.vue",
  components: { "vue-recaptcha": VueRecaptcha },
  directives: { maska },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {
         nome: "",
         dataNascimento: "",
         cpf_cnpj: "",
         nomeMae: "",
         cep: "",
         endereco: "",
         numero: "",
         complemento: "",
         municipio: "",
         uf: "",
         contato: "",
         email: "",
        g_recaptcha_response: "",
      },
      show: true,
      cpf_cnpf: false,
      meuCep: false,
      reCAPTCHA: true,
      msgRecaptcha: false,
      verifyrecapcha: false,
      spinner: false,
    };
  },
  validations() {
    return {
      form: {
        nome: { required, minLength: minLength(3), maxLength: maxLength(60) },
        dataNascimento: { required },
        cpf_cnpj: { required, minLength: minLength(3) },
        nomeMae: { required },
        cep: { required, minLength: minLength(9) },
        endereco: { required },
        numero: { required },
        email: { required, email },
      },
    };
  },
  created() {},
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.v$.$touch();
      if (
        !this.v$.$invalid &&
        !this.meuCep &&
        !this.cpf_cnpf &&
        !this.reCAPTCHA
      ) {
        var data = JSON.stringify(this.form);
        this.spinner = true,
        Cadastro.postCadastro(data).then((result) => {
          if (result.data.success == true) {
            this.spinner = false;
            this.msgRecaptcha = false;
            this.reCAPTCHA = true;
            this.resetRecaptcha();
            this.limpaForm();
            this.v$.$reset();
            notyf.success(result.data.message);
            window.scrollTo(0, 0);
            return;
          } else {
            notyf.error(result.data.message);
            window.scrollTo(0, 0);
          }
        });
      } else {
        this.msgRecaptcha = this.reCAPTCHA;
        notyf.error("Campos obrigatórios vazio.");
        window.scrollTo(0, 0);
      }
    },
    searchCPF_CNPJ() {
      if (this.form.cpf_cnpj.length === 18) {
        this.cpf_cnpf = !Helper.validarCNPJ(this.form.cpf_cnpj);
      } else {
        this.cpf_cnpf = !Helper.validatorCPF(this.form.cpf_cnpj);
      }
    },
    searchCep() {
      Helper.searchCep(this.form.cep)
        .then((response) => response.json())
        .then((result) => {
          if (result.erro) {
            this.meuCep = true;
            this.v$.$invalid = true;
          } else {
            this.meuCep = false;
          }
          this.form.endereco = result.logradouro;
          this.form.municipio = result.localidade;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    limpaForm() {
      let vm = this;
      (vm.form.nome = ""),
        (vm.form.dataNascimento = ""),
        (vm.form.cpf_cnpj = ""),
        (vm.form.nomeMae = ""),
        (vm.form.cep = ""),
        (vm.form.endereco = ""),
        (vm.form.numero = ""),
        (vm.form.complemento = ""),
        (vm.form.municipio = ""),
        (vm.form.uf = ""),
        (vm.form.contato = ""),
        (vm.form.email = ""),
        (vm.form.g_recaptcha_response = "");
    },
    verifyMethod(response) {
      if (response) {
        this.form.g_recaptcha_response = response;
        this.msgRecaptcha = false;
        this.reCAPTCHA = false;
      }
    },
    expiredMethod() {
      let mv = this;
      mv.reCAPTCHA = true;
    },
    resetRecaptcha() {
      window.grecaptcha.reset();
    },
  },
   mounted() {
     this.form.email = this.$route.query.email != undefined ? this.$route.query.email : "";
    }
};
</script>
<style rel="stylesheet" scoped>
span {
  color: red;
  font-size: 13px;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #f20000;
  border-right: 1.1em solid #f20000;
  border-bottom: 1.1em solid rgb(255 255 255 / 0%);
  border-left: 1.1em solid #f20000;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  top: 140px;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#loadingDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:rgb(255 255 255 / 52%);
  display: block;
}
.paragrafo {
  text-align: center;
  padding-top: 78px;
  font-weight: 500;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #212529;
  font-size: 2em;
  color: #212529;
  font-weight: 700;
}
</style>