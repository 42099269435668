<template>
  <footer>
    <div class="container">
      <div class="justify-content-sm-center row">
        <div class="menu-block position-relative col-sm-12 col-md-3" style="width: max-content">
          <router-link to="/">
            <!--suppress HtmlUnknownTarget -->
            <span style="font-size: 22px; font-weight: 900; position: relative; top: -10px; color: #58595b">
              <span style="color: #6f595b">My</span>
              <span>Cloud</span>
              <span style="color: #6f595b">Coin</span>
            </span>
          </router-link>
          <div class="copyright" style="margin-top: -10px">
            <span style="font-size: 12px">© 2022 MyCloudCoin.</span><br><br>
          </div>
        </div>
        <div class="menu-block col-sm col-md col-xl-2 d-none d-xl-block col-border-right pl-md-4 pl-lg-3" style="margin: 0 auto">
          <div class="copyright" style="margin-top: -5px">
            <span style="font-size: 12px;">CLOUDCRM EIRELI</span><br>
            <span style="font-size: 11px">CNPJ 26.115.245/0001-58</span><br>
            <span style="font-size: 12px">Todos os direitos reservados.</span>
          </div>
        </div>
        <div class="menu-block col-sm-12 col-md-3 col-xl-2 col-border-right col-padding">
          <ul>
            <li><router-link to="/terms">Termos de Uso</router-link></li>
            <li><router-link to="/privacy-policy">Politica de Privacidade</router-link></li>
            <li><router-link to="/support">Suporte</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script rel="script">

  export default {
    name: 'Footer'
  }

</script>
<style rel="stylesheet" scoped>

  footer {
    padding: 30px 0;
    text-align: left;
    background: #272727;
    font-weight: 400;
    font-size: 14px;
    color: #aaa;
  }

  footer .menu-block {
    padding-top: 20px;
  }

  footer .social-block {
    margin-top: 1.1em;
  }

  footer .social-block .social {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 23px;
    width: 23px;
    height: 23px;
    line-height: 23px;
    font-size: 13px;
    color: #fff;
    margin: auto 3px;
    text-align: center;
    vertical-align: middle;
    background-color: #555;
  }

  footer .social-block .social-facebook svg {
    width: 1em;
    height: 1em;
  }

  footer .social-block .social-instagram svg {
    width: 1.2em;
    height: 1.2em;
    position: relative;
    left: .03em;
    top: -.03em
  }

  footer .social-block .social-twitter svg {
    width: 1em;
    height: 1em;
  }

  footer .social-block .social-wordpress svg {
    width: 1.3em;
    height: 1.3em;
  }

  footer h1 {
    margin-bottom: 30px;
  }

  footer h1 {
    font-size: inherit;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1.2em;
  }

  footer ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 0;
  }

  footer ul li {
    margin-bottom: 0.3em;
  }

  footer a {
    color: inherit;
    text-decoration: none;
    background-color: transparent;
  }

  footer .exchange svg {
    width: 1em;
    margin-left: .35em;
  }

</style>
<style>

  footer .dropdown > button.btn {
    border: none;
    background: transparent;
    margin: .7em 0;
    padding: 3px 0 3px 2px;
    color: #a5a5a5;
  }

  footer .dropdown > button.btn:focus {
    border: none;
    box-shadow: none;
  }

  footer .dropdown > ul.dropdown-menu {
    background-color: #2c2c2c;
    margin-top: .7em!important;
  }

  footer .dropdown > ul.dropdown-menu button {
    color: #aaa;
  }

  footer .dropdown .dropdown-item.active,
  footer .dropdown .dropdown-item:active {
    background-color: #313131;
  }

  footer .dropdown .dropdown-item:focus,
  footer .dropdown .dropdown-item:hover {
    background-color: #353535;
  }

</style>