import axios from 'axios'
import ApiService from './api.service';

const Cadastro = {

  _chave : "4b07e1d2f35f844b43d1b83efc29191401de0a61",

  postCadastro(data){  
    var instance = axios.create();
    /*instance.defaults.headers.common['Authorization'] = this._chave ;*/
    return instance.post(ApiService.getBaseUrl() + "/cadastro", data);
}

};

export default Cadastro