<template>
  <div class="privacy-policy">
    <div class="container">
      <div class="row">
        <h2 class="col">Política de Privacidade e de Proteção de Dados Pessoais</h2>
      </div>
      <div class="row">
        <p><b>1. Introdução</b></p>
        <p>
          1.1. Seja Bem vindo(a) nà My Cloud Coin. Nossa política de privacidade e proteção de dados é simples. NÃO
          COMPARTILHAREMOS SEUS DADOS COM NINGUÉM que não esteja diretamente ligado, ou seja indispensável para
          realizar a operação de compra ou venda de criptoativos que você fizer através de nossa plataforma.
        </p>
        <p>
          1.2. Você só poderá comprar e/ou vender criptoativos através da My Cloud Coin se estiver, integralmente e
          sem ressalvas, de acordo com esta Política de Privacidade e de Proteção de Dados Pessoais.
        </p>
        <p>
          1.3. Nossa Politica de privacidade e proteção de dados foi desenvolvida e está em conformidade com as Leis
          nº 13.709/2018 (“Lei Geral de Proteção de Dados”) e nº 12.965/2014 (“Marco Civil da Internet”) para
          esclarecer a você (i) quais dados pessoais, como e para que são tratados; (ii) quais são os seus direitos,
          nos termos da legislação e das normas aplicáveis à proteção de dados pessoais e (iii) quais são as nossas
          obrigações, aplicando-se a nossas interações off-line e on-line e a todas as atividades e serviços
          fornecidos.
        </p>
        <p><b>2. Quem é o My Cloud Coin?</b></p>
        <p>
          2.1. Somos a <b>CLOUDCRM SISTEMA DE INFORMÁTICA EIRELI</b>, pessoa jurídica de direito privado, inscrita no
          CNPJ sob o no. 26.115.245/0001-58, estabelecida na Av. Paulista, 2421, Andar 01 Cxpst 152, Bela Vista,
          São Paulo-SP, CEP: 01.311-300.
        </p>
        <p><b>3. Quais dados tratamos?</b></p>
        <p>
          3.1. Por dados pessoais, entende-se toda informação relacionada à pessoa natural, identificada ou
          identificável, tais como nome, documento de identidade, endereço, informações de contato etc.
        </p>
        <p>
          3.2. Seus dados podem ser coletados pela My Cloud Coin, quando vocênavega em nosso site; realiza seu cadastro
          e transações na Plataforma; compartilha informações fundamentais para a execução dos serviços e aprimoramento
          dos processos da My Cloud Coin; e entra em contato com nossos canais de atendimento.
        </p>
        <p>
          3.3. Todas as fontes de coleta de dados garantem a proteção e a confidencialidade de seus dados de acordo
          com as práticas aqui descritas, com a legislação e com as normas aplicáveis.
        </p>
        <p>
          3.4. Os dados pessoais que serão solicitados para que você tenha acesso integral aos nossos serviços,
          conforme o caso, são: na hipótese de pessoa física: CPF, data de nascimento e e-mail.
        </p>
        <p>
          3.5. Também solicitaremos que você envie cópia digitalizada de um documento de identidade válido com foto,
          além de selfie, e, em determinadas situações, vídeo de confirmação, que se caracterizam como dados pessoais
          sensíveis essenciais para controle de identificação nos sistemas informáticos e, sem os quais, a My Cloud
          Coin não consegue prestar os serviços aqui previstos. Essas são as mínimas informações necessárias para que
          possamos prestar os serviços de forma segura, ou seja, identificando você adequadamente, podendo ser
          utilizada biometria facial.
        </p>
        <p>
          3.6. Sem o envio desses documentos e informações, a utilização de nossos canais, serviços e funcionalidades
          poderá sofrer restrições e até mesmo ser inviabilizada. A My Cloud Coin poderá, conforme o caso, solicitar
          outros documentos necessários a você, com o intuito de garantir o pleno acesso aos serviços da Plataforma,
          tais como comprovantes de renda e de residência.
        </p>
        <p>
          3.7. Quando você realiza seu cadastro, navega em nossa Plataforma, e utiliza os serviços, os seguintes dados
          e informações podem ser coletados, dentre outros:
        </p>
        <p>
          <b>a) Dados de contato</b>: números de telefone, com o objetivo de dar maior segurança aos serviços e
          fidelidade das informações que você fornecer;
        </p>
        <p>
          <b>b) Credenciais</b>: coletamos hashs criptográficos de senhas, palavras seguras, PIN de segurança e
          informações de segurança necessárias escolhidas por você para o processo de autenticação, acesso a contas
          e transações, para o adequado controle de acesso a sua conta;
        </p>
        <p>
          <b>c) Dados demográficos</b>: gênero, endereço, escolaridade, renda;
        </p>
        <p>
          <b>d) Dados financeiros</b>: coletamos dados necessários para a realização de saques em Reais (R$), como
          banco, número da conta e agência, bem como dados e histórico de operações realizadas na Plataforma para seu
          controle. Coletamos chaves de API de negociações e endereço de carteira criptografada;
        </p>
        <p>
          <b>e) Dados de uso</b>: além de dados relacionados às suas transações com Criptoativos, incluindo seu perfil
          de usuário, guardamos dados relacionados a sua interação com nossos canais de comunicações, como duração da
          visita, caminhos de navegação na Plataforma, comportamento de pageview, características do dispositivo de
          acesso, navegador, endereço de Protocolo de Internet (IP) com data e hora, origem do IP, banda e provedor de
          serviços de internet (ISP), sistema operacional, fabricante do dispositivo, operadora, modelo, redes de Wi-fi,
          número de telefone, dados de dispositivo, identificador de dispositivo (IMEI/MEID), operadora de telefonia
          móvel e país de registro e configuração;
        </p>
        <p>
          <b>f) Dados de atendimento</b>: sua interação em nossos canais de atendimento também fica registrada, assim
          como outros detalhes de seu contato, podendo incluir conteúdo de conversas em chat;
        </p>
        <p>
          <b>g) Dados de localização</b>: podemos, por meio de nossa Plataforma, coletar dados de localização
          originados de dados de GPS (Sistema de Navegação Global), GNSS (Global Navigation Satellite System), de
          torres de comunicações móveis, pontos de acesso Wi-fi ou localização vinda de seu IP.
        </p>
        <p>
          3.9. A contratação dos serviços prestados pela My Cloud Coin pressupõe o envio de mensagens eletrônicas
          (como, por exemplo, e-mails, notificações e SMS) de cunho de segurança e administrativo, sendo esse envio
          imprescindível à execução e ao desenvolvimento de nossa atividade. Caso você não concorde com este
          procedimento, é necessário solicitar o cancelamento de sua conta nA My Cloud Coin.
        </p>
        <p><b>4. Para que utilizamos os dados coletados?</b></p>
        <p>
          <b>a)</b> Permitir a realização de transações com Criptoativos disponibilizados e suportados pela My Cloud
          Coin, criar ordens de compra e venda e gerar e permitir acesso a sua carteira virtual;
        </p>
        <p>
          <b>b)</b> Aprimorar nossos produtos, processos e serviços;
        </p>
        <p>
          <b>c)</b> Personalizar conteúdo, realizar alterações em nossos produtos e canais;
        </p>
        <p>
          <b>d)</b> Disponibilizar novas funcionalidades, produtos e dinâmicas promocionais;
        </p>
        <p>
          <b>e)</b> Ofertar novos produtos e/ou serviços a você, bem como atendimento personalizado e acompanhamento
          de carteira de investimento;
        </p>
        <p>
          <b>f)</b> Realizar pesquisas e campanhas, a fim de melhorar continuamente a experiência de uso da My Cloud
          Coin pelos usuários.
        </p>
        <p>
          <b>g)</b> Solucionar problemas e dúvidas, garantindo a qualidade de nossos serviços e atendimento.
        </p>
        <p>
          <b>h)</b> Estabelecer um diálogo relevante e assertivo, respeitando suas preferências de interação, bem como
          para envio de avisos importantes, como comunicados, registro de alterações de software, funcionalidades,
          condições e políticas, entre outras;
        </p>
        <p>
          <b>i)</b> Sofisticar ainda mais nossa segurança, agindo de forma eficaz em atividades suspeitas e violações
          de termos ou políticas;
        </p>
        <p>
          <b>j)</b> Analisar o desempenho, as tendências e medir a audiência da Plataforma, verificar os seus hábitos
          de navegação na Plataforma, a forma pela qual chegou na Plataforma (por exemplo, por meio de links de outros
          sites, buscadores ou diretamente), avaliar estatísticas relacionadas ao número de acessos e uso da Plataforma,
          seus recursos e funcionalidades;
        </p>
        <p>
          <b>k)</b> Avaliar e monitorar riscos para segurança da Plataforma, aperfeiçoando e desenvolvendo nossas
          ferramentas de segurança, inclusive em cumprimento a nossas diretrizes de prevenção à Lavagem de Dinheiro e
          Combate ao Financiamento ao Terrorismo; e
        </p>
        <p>
          <b>l)</b> Cumprimento de obrigações legais e regulatórias.
        </p>
        <p>
          <b>4.1.</b> Para fins de sua qualificação, treinamento e para a sua segurança, A My Cloud Coin pode
          monitorar ou gravar conversas telefônicas com você ou com pessoas agindo em seu nome. Ao se comunicar
          com A My Cloud Coin, você entende, concorda e autoriza que as comunicações podem ser ouvidas, monitoradas
          e/ou gravadas sem aviso ou notificação prévia.
        </p>
        <p>
          <b>4.2.</b> Você concorda e autoriza A My Cloud Coin a utilizar, copiar, reproduzir, disponibilizar,
          transmitir, tratar, compartilhar e traduzir para outros idiomas todos e quaisquer depoimentos, declarações,
          opiniões, impressões, comentários e sugestões que você decidir deixar público em nossa Plataforma, inclusive
          redes sociais, associados ou não ao seu nome e sua fotografia de perfil nessas redes sociais, sem que nenhuma
          contraprestação seja devida pelA My Cloud Coin.
        </p>
        <p>
          <b>4.3.</b> Além do disposto acima, A My Cloud Coin, respeitando a sua privacidade, envia mensagens por meios
          eletrônicos como central de notificações na própria Plataforma, e-mails, SMS e notificações para confirmar
          atividades da Plataforma, para fins publicitários e utiliza tecnologias como cookies, pixel tags,
          armazenamento local ou outros identificadores, de dispositivos móveis ou não para autenticação de contas,
          aperfeiçoamento de serviços, personalização e para comunicados de massivo interesse. A frequência de envio
          pode variar, de acordo com a sua interação com essas comunicações. A qualquer momento, você poderá solicitar
          a interrupção desses e-mails, SMS e notificações pelos nossos canais de comunicação, o que será atendido pela
          My Cloud Coin em até 10 (dez) dias contados da sua solicitação, acessando:
        </p>
        <p>
          <b>4.4.</b> Os dados serão tratados pela My Cloud Coin durante o prazo em que forem necessários ou
          pertinentes ao alcance das finalidades previstas, ressalvadas as hipóteses de conservação prescritas na
          legislação aplicável, em especial na Lei Geral de Proteção de Dados, Marco Civil da Internet, Código de
          Defesa do Consumidor e Código Civil.
        </p>
        <p><b>5.</b> Quais são os direitos e deveres dos usuários?</p>
        <p>
          <b>5.1.</b> A My Cloud Coin assegura direitos que você possui em conformidade com a Lei Geral de Proteção de
          Dados, com o Marco Civil da Internet e com as demais leis setoriais brasileiras relacionadas à proteção de
          dados, quais sejam:
        </p>
        <p>
          <b>a) Acesso aos dados pessoais</b>: permite que você acesse em sua própria conta seus dados pessoais
          fornecidos em seu cadastro e que solicite informações adicionais, se desejar;
        </p>
        <p>
          <b>b) Retificação dos seus dados pessoais</b>: permite que você possa solicitar a correção e/ou retificação
          dos seus dados pessoais, a qualquer momento, caso você identifique que algum(s) dele(s) esteja(m)
          incompleto(s), inexato(s) ou desatualizado(s);
        </p>
        <p>
          <b>c) Bloqueio ou eliminação de dados pessoais desnecessários, excessivos ou tratados em desconformidade
          com a Lei Geral de Proteção de Dados</b>: permite que você peça para cessar o tratamento de seus dados
          pessoais, sendo que a medida tomada será avaliada e atendida de acordo com cada caso, resguardado o dever
          de guarda pelo prazo legal do Marco Civil da Internet, do Código de Defesa do Consumidor e do Código Civil,
          observado o prazo de prescrição de eventuais reclamações judiciais ou administrativas;
        </p>
        <p>
          <b>d) Direito de portabilidade de dados pessoais:</b> permite que você requeira que A My Cloud Coin forneça
          a você, ou a terceiros que você escolheu, os seus dados pessoais em formato estruturado e interoperável;
        </p>
        <p>
          <b>e) Direito de exclusão de dados pessoais tratados com consentimento do titular dos dados pessoais </b>:
          permite que você solicite a exclusão de seus dados pessoais quando o tratamento destes dados for facultativo
          e tiver como base legal o seu consentimento, ressalvada a manutenção de dados necessária para (i) cumprimento
          de obrigação legal ou regulatória; (ii) estudo por órgão de pesquisa, garantida, sempre que possível, a
          anonimização dos dados pessoais; (iii) por decisão judicial ou administrativa que o exija; ou (iv) em razão
          do dever de guarda pelo prazo legal do Marco Civil da Internet, Código de Defesa do Consumidor e do Código
          Civil, observado o prazo de prescrição de eventuais reclamações judiciais ou administrativas.
        </p>
        <p>
          <b>f) Direito de informação sobre o compartilhamento de dados pessoais</b>: permite que você possa solicitar
          informação acerca de terceiros com os quais A My Cloud Coin compartilhe seus dados pessoais; e
        </p>
        <p>
          <b>g)</b> Direito de revogar o consentimento a qualquer momento e o direito de não o fornecer e as
          consequências de não o fornecer:permite que você revogue o seu consentimento a qualquer momento, ressalvado
          que a depender da natureza dos dados pessoais, a revogação poderá implicar a impossibilidade de uso da
          Plataforma em caráter definitivo. A revogação do consentimento não terá efeitos retroativos.
        </p>
        <p>
          <b>5.2.</b> Para exercer seus direitos, você poderá utilizar os canais de contato de nossa Plataforma,
          preferencialmente, exceto se o seu contrato com A My Cloud Coin dispuser de forma contrária.
        </p>
        <p>
          <b>5.3.</b> Se você é um de nossos clientes, antes de atender à sua solicitação, A My Cloud Coin solicitará
          informações adicionais para confirmar a sua identidade, por meio de uma das nossas ferramentas de KYC - Know
          Your Client (Conheça o seu cliente). Nas hipóteses em que A My Cloud Coin não seja o Controlador dos dados
          pessoais do solicitante, A My Cloud Coin informará o solicitante a respeito de sua posição como Operador de
          dados pessoais e, se possível, indicará o Controlador, responsável por atender a sua solicitação.
        </p>
        <p>
          <b>5.4.</b> Além de direitos, você também tem alguns deveres dispostos aqui nesta Política, alguns deles
          disciplinados nos nossos termos de uso, outros em contratos específicos com você. Caso você não os observe,
          especialmente os deveres relacionados à segurança de seus dados pessoais, tais como atos de divulgação de
          suas informações de acesso (login e senha) para terceiros, utilização de computadores de acesso público
          (ex: lan houses) ou qualquer outra forma de conexão com a internet que não seja privada e segura, ou, ainda,
          utilize aparelhos móveis com jailbreak ou que tenha aplicativos provenientes de lojas não oficiais, A My
          Cloud Coin não será responsável por atos ou fatos decorrentes do descumprimento destes deveres e/ou nos
          quais você atue com culpa exclusiva ou concorrente.
        </p>
        <p><b>6. Utilização de cookies e tecnologias semelhantes</b></p>
        <p>
          <b>6.1.</b> Os cookies, a ferramenta Data Management Platform (“DMP”) e tecnologias semelhantes suportam o
          processo de identificação de cliente, comunicação e demais ações de marketing, além de possibilitarem a
          proteção dos dados coletados. Eles armazenam informações em navegadores da web, usados em computadores,
          telefones e outros dispositivos, que trazem a informação sobre seu uso em nossa Plataforma. São úteis nos
          processos de autenticação, publicidade, recomendações, mensurações de audiência, recursos e funcionalidades
          dos canais, análises de segurança para aperfeiçoamento e desenvolvimento de ferramentas antifraude.
        </p>
        <p>
          <b>6.2.</b> A My Cloud Coin utiliza cookies próprios essenciais para controle, monitoramento e rastreamento
          de eventuais vulnerabilidades, riscos de incidentes e incidentes de segurança da informação, para agir de
          modo preventivo e prover um ambiente seguro para nossos clientes. Também utiliza cookies de terceiros para
          análises estatísticas de dados de navegação, a fim de avaliar nossa Plataforma e melhorar constantemente
          nossos serviços e produtos oferecendo experiência de uso mais customizada.
        </p>
        <p>
          <b>6.3.</b> Os cookies próprios são essenciais para que A My Cloud Coin possa garantir um ambiente de
          navegação seguro e possa prestar serviços a você de forma adequada, não podendo ser desabilitados. Você
          pode desabilitar os cookies de terceiros nas opções de configuração na Plataforma, estando ciente de que
          as funcionalidades e serviços da My Cloud Coin podem não ser fornecidos ou ser fornecidos parcialmente em
          caso de desabilitação.
        </p>
        <p>
          <b>6.4.</b> Ao acessar e se conectar nos canais da My Cloud Coin sem desabilitar essas tecnologias, você
          concorda em receber uma experiência de navegação mais personalizada e autoriza o armazenamento, tratamento
          e compartilhamento, nos termos aqui previstos, das informações já mencionadas.
        </p>
        <p><b>7. Por quanto tempo os dados pessoais são guardados?</b></p>
        <p>
          <b>7.1.</b> A My Cloud Coin armazenará os seus dados pessoais enquanto perdurar a relação contratual com
          você, salvo disposições legais ou regulatórias em sentido contrário e necessário pelos prazos máximos
          prescricionais previstos no Marco Civil da Internet, no Código de Defesa do Consumidor e no Código Civil.
          Nos casos em que não existir relação contratual, A My Cloud Coin guardará as informações que você concordou
          em nos fornecer até ulterior pedido para descarte dos seus dados e de acordo com a legislação aplicável.
        </p>
        <p><b>8. Os dados coletados são compartilhados com terceiros?</b></p>
        <p>
          <b>8.1.</b> A My Cloud Coin poderá compartilhar os seus dados pessoais e demais dados indicados no item 3.8,
          supra, com empresas pertencentes ao mesmo grupo econômico que A My Cloud Coin, para processamento das
          operações de compra e venda de Criptoativos, oferecimento de funcionalidades específicas (tais como
          visualização e/ou acesso à sua conta da My Cloud Coin por meio do aplicativo do Ecommerce Bank, caso você
          seja cliente do Ecommerce Bank), desenvolvimento e oferta de produtos, serviços e cadastro em referidas
          empresas. Quando o compartilhamento de dados pessoais com terceiros for necessário para desenvolvimento e
          oferta de produtos e serviços que melhor atendam aos seus interesses, geração de dados estatísticos e
          agregados acerca do uso da Plataforma, A My Cloud Coin adotará, na medida do possível, a anonimização
          destes dados.
        </p>
        <p>
          <b>8.2.</b> A My Cloud Coin também poderá compartilhar os seus dados pessoais em processos de auditoria para
          operações societárias ou com parceiros e prestadores de serviços nos casos necessários para execução de
          contrato com você.
        </p>
        <p>
          <b>8.3.</b> A My Cloud Coin pode coletar informações a respeito de você por meio de entidades de verificação
          de identidade e bureau de dados para detectar possíveis fraudes, bem como de bureaus de crédito, de perfis e
          riscos de crédito, para análise de crédito, incluindo, mas não se limitando a Accuity, PH3A, Serasa Experian,
          Receita Federal, Advice, UpMiner e Google.
        </p>
        <p>
          <b>8.4.</b> A My Cloud Coin pode compartilhar seus dados pessoais com seus parceiros para prestar seus
          serviços, executar o contrato com você, análises de crédito e prevenção de fraudes, bem como para
          implementar suas políticas de Compliance e Know Your Client, Prevenção à Lavagem de Dinheiro e Financiamento
          ao Terrorismo.
        </p>
        <p>
          <b>8.5.</b> A My Cloud Coin poderá compartilhar seus dados pessoais com autoridades públicas, em caso de
          processos investigativos ou solicitações administrativas ou judiciais no Brasil ou no exterior (neste caso,
          atentando-se para conferir aos dados pessoais o mesmo nível de proteção que o oferecido no Brasil) ou por
          ordem de uma autoridade competente, como agências reguladoras, órgãos do governo, de acordo com o disposto
          na legislação e nas normas aplicáveis.
        </p>
        <p>
          <b>8.6.</b> A My Cloud Coin poderá realizar transferências internacionais de dados pessoais, seja por força
          de contratos com provedores de serviços tecnológicos localizados fora do país, seja por força de requisição
          de autoridades de proteção de dados pessoais ou entidades governamentais estrangeiras, tudo em conformidade
          com a Lei Geral de Proteção de Dados.
        </p>
        <p><b>9. Como os dados coletados são tratados?</b></p>
        <p>
          <b>9.1.</b> A My Cloud Coin incorporou todas as exigências da Lei Geral de Proteção de Dados e de outras leis
          e normas a respeito de proteção de dados no tratamento dos seus dados pessoais, adotando as seguintes
          premissas:
        </p>
        <p>
          <b>a) Minimização de dados.</b> Em todos o tratamento de dados pessoais, seja em meio físico, seja digital,
          A My Cloud Coin cuida para coletar e tratar dados pessoais minimamente necessários e compatíveis com
          finalidades, descritas e informadas aos titulares (atendendo aos princípios da finalidade, adequação e
          necessidade).
        </p>
        <p>
          <b>b) Transparência</b>. A My Cloud Coin cuida para que todo e qualquer tratamento de dados pessoais,
          inclusive sob as diferentes formas de coleta, uso e guarda, seja de pleno conhecimento dos titulares
          quanto ao tipo de dado, finalidade, bem assim sua conservação e período de guarda.
        </p>
        <p>
          <b>c) Confidencialidade</b>. A My Cloud Coin adota medidas organizacionais e técnicas voltadas à
          confidencialidade dos dados pessoais sob sua responsabilidade, gerenciando e controlando acesso
          somente a pessoas autorizadas, bem assim adotando medidas de rastreamento de logs para antecipação
          e mitigação de riscos.
        </p>
        <p>
          <b>d) Prevenção e segurança.</b> A My Cloud Coin promove avaliação periódica de riscos e, com isto, atualiza
          as medidas técnicas de segurança da informação para garantir a adequada proteção dos dados pessoais. Neste
          sentido, também cuida para que, na medida do possível, haja a anonimização ou, ao menos, a pseudonimização
          de dados pessoais objeto de pesquisa científica, análise estatística e avaliação de qualquer natureza para
          atender a interesses legítimos da My Cloud Coin, sobretudo aqueles que forem compartilhados com terceiros.
        </p>
        <p>
          <b>e) Livre acesso e qualidade de dados</b>. A My Cloud Coin garante aos titulares o acesso fácil e gratuito
          sobre seus dados pessoais, inclusive para garantir que os dados sejam claros, precisos e atualizados.
        </p>
        <p>
          <b>f) Não discriminação</b>. A My Cloud Coin zela para que todo o tratamento de dados pessoais seja feito de
          forma lícita, sem qualquer tipo de discriminação de qualquer natureza.
        </p>
        <p>
          <b>g) Relação com terceiros</b>.Em todas as suas relações com terceiros que envolvam fluxo de dados pessoais
          sob a responsabilidade da My Cloud Coin, seja com conveniadas, parceiras ou prestadores de serviços, A My
          Cloud Coin zelará para que os contratos contenham cláusulas transmitindo instruções e disciplinando deveres e
          obrigações dos terceiros quanto à proteção de dados pessoais dos quais você seja o titular.
        </p>
        <p>
          <b>h) Responsabilização e prestação de contas.</b> A My Cloud Coin atentará continuamente para adotar
          medidas eficazes e capazes de comprovar a observância e o cumprimento das normas de proteção de dados
          pessoais.
        </p>
        <p>
          <b>9.2</b>. Todos os dados pessoais são guardados em ambiente seguro, em ambiente cloud (“nuvem”) e em
          servidores físicos de prestadores escolhidos com a premissa de respeito à legislação de proteção de dados,
          sendo que A My Cloud Coin não só observa todas as regras para transferência internacional de dados pessoais,
          como cuida para transmitir a seus prestadores de serviços tecnológicos as diretrizes da companhia para
          proteção dos dados e garantindo aos titulares o mesmo nível de proteção exigido pela legislação nacional.
        </p>
        <p><b>10. Quais são as políticas de segurança adotadas?</b></p>
        <p>
          <b>10.1.</b> A My Cloud Coin adota medidas organizacionais, treinando e capacitando seu pessoal interno,
          bem como medidas técnicas voltadas à segurança da informação, para proteção dos dados pessoais, contra
          divulgação não autorizada, acesso indevido, modificação e perda ou vazamento de dados, de maneira acidental
          ou ilícita. A My Cloud Coin aplica as melhores práticas de segurança no tratamento de dados pessoais, tais
          como criptografia, monitoramento e testes de segurança periódicos, firewall, entre outros. Ainda assim, a
          despeito de todos os seus esforços para a segurança da informação, o acesso ou uso não autorizado as áreas
          restritas de cliente por falha do próprio titular dos dados (você), ou ainda falha de hardware ou software
          e ataques cibernéticos podem comprometer a segurança dos seus dados pessoais.
        </p>
        <p>
          <b>10.2.</b> A My Cloud Coin adota mecanismos de controles de acesso e rastreamento de logs, com níveis
          diferentes de restrição de acesso aos dados coletados, garantindo em seus contratos específicos – seja com
          pessoal interno, seja com colaboradores ou prestadores de serviços - que a inobservância a esta regra implica
          multas e rescisões de contrato.
        </p>
        <p>
          <b>10.3.</b> A My Cloud Coin não envia emails ou notificações solicitando confirmação ou dados pessoais,
          senhas, números de cartão de crédito, endereço de carteira criptografada, etc.; isto pode ser um phishing,
          prática fraudulenta que tem por objetivo induzir você a compartilhar informações pessoais, logins e senhas
          com pessoas mal-intencionadas. Também não envia mensagens eletrônicas com anexos que possam ser executados
          (extensões: .exe, .com, entre outras) ou links para eventuais downloads. Nunca forneça resposta a esses
          e-mails e reporte em nossos canais de atendimento.
        </p>
        <p><b>11. Foro competente</b></p>
        <p>
          Fica eleito o Foro da Comarca de São Paulo/SP para dirimir quaisquer controvérsias que surjam em relação
          a esta Política de Privacidade e proteção de dados pessoais.
        </p>
        <p><b>12. Alteração na política</b></p>
        <p>
          <b>12.1.</b> A My Cloud Coin se compromete a revisitar essa Política de Privacidade e de Proteção de Dados
          Pessoais periodicamente para assegurar sua conformidade com a lei, bem como para ajustar-se às orientações
          da Autoridade Nacional de Proteção de Dados (ANPD), podendo, por estas razões, modificar os seus termos a
          qualquer tempo. Sempre que houver uma mudança relevante, tal como uma nova finalidade para os dados pessoais
          já informados, você será notificado por meio das informações de contato disponibilizadas por você ou por um
          aviso na Plataforma. Na notificação, você terá acesso ao novo texto da Política de Privacidade e de Proteção
          de Dados Pessoais.
        </p>
        <p><b>13. Contato e dados do DPO</b></p>
        <p>
          <b>13.1.</b> Caso tenha alguma dúvida e/ou precise tratar de qualquer assunto relacionado a esta
          Política de Privacidade e de Proteção de Dados Pessoais, por favor, entre em contato conosco no
          <a href="mailto:juridico@cloudcrm.tech">juridico@cloudcrm.tech.</a> Se você é um cliente nosso,
          mantenha sempre seus canais de contato atualizados, para podermos contatá-lo por email,
          preferencialmente.
        </p>
        <p>
          <b>13.2. Nosso encarregado ou DPO</b> (Data Protection Officer) é Angelo Braga e seu email é
          <a href="mailto:juridico@cloudcrm.tech">juridico@cloudcrm.tech.</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script rel="script">

  export default {
    name: "PrivacyPolicy.vue"
  }

</script>
<style ref="stylesheet" scoped>

  .privacy-policy {
    padding: 20px 0 50px 0;
  }

  .privacy-policy > .container {
    max-width: 850px;
  }

  .privacy-policy h2 {
    margin-bottom: 30px;
    margin-top: 2rem;
    border-bottom: 1px solid #c4c4c4;
    color: #666;
    font-size: 20px;
    font-weight: bold;
    line-height: 130%;
    padding-bottom: 10px;
  }

  .privacy-policy p {
    text-align: justify;
  }

</style>