<template>
  <div class="terms">
    <div class="container">
      <div class="row">
        <h2 class="col">
          CONTRATO DE INTERMEDIAÇÃO NA COMPRA, VENDA e PERMUTA DE CRIPTOATIVOS E TERMOS E CONDIÇÕES DE USO DA
          PLATAFORMA ("Termos e Uso")
        </h2>
      </div>
      <div class="row">
        <p>
          <b>CLOUDCRM SISTEMA DE INFORMÁTICA EIRELI</b>, pessoa jurídica de direito privado, inscrita no CNPJ sob o
          no. 26.115.245/0001-58, estabelecida na Av. Paulista, 2421, Andar 01 Cxpst 152, Bela Vista, São Paulo-SP,
          CEP: 01.311-300, doravante denominada simplesmente como <b>CLOUD CRM</b>, e de outro lado a pessoa física
          ou jurídica, concordante do presente Termo, devidamente identificada na base de dados de nossa Plataforma,
          doravante identificado por <b>Usuário</b>, celebram o disposto pelos termos que seguem.
        </p>
        <p>
          A <b>CLOUD CRM</b> presta serviços relacionados à blockchain e criptomoedas, especialmente ligados à
          intermediação de compra e venda de criptoativos, garantindo segurança e sigilo nas transações realizadas
          pelos clientes.
        </p>
        <p>
          Vale destacar a diferença entre esse tipo de operação, que se assemelha às operações de OTC do mercado
          financeiro tradicional, com as empresas denominadas “Exchange” de criptoativos.
        </p>
        <p>
          A “Exchange” possui um livro de ofertas aberto, onde é possível lançar ordens de compra ou de venda de
          criptoativos, além de custodiar moeda corrente nacional e criptoativos dos clientes.
        </p>
        <p>
          Já a operação de intermediação, realizada pela <b>CLOUD CRM</b>, consiste exclusivamente na compra ou
          venda de criptoativos por conta e ordem de terceiro, de modo que não há custódia de moeda corrente
          nacional ou de criptoativos de terceiros.
        </p>
        <p>
          O Usuário declara possuir conhecimento suficiente para operar (comprar e vender) junto a Plataforma
          Própria, não havendo qualquer dúvida ou fato que possa caracterizar sua hipossuficiência em relação
          ao mesmo, possuindo pleno e total gozo de sua capacidade civil, bem como está apto à prática de todo
          e qualquer ato necessário à validade das operações.
        </p>
        <p>
          O Usuário declara-se ciente que a <b>CLOUD CRM</b> não é consultoria de investimentos, mas apenas um
          entreposto comercial de compra e venda de Criptoativos, não tendo qualquer responsabilidade pelo uso
          e investimentos realizados pelos Usuários, ou por qualquer perda direta ou indireta de lucros.
        </p>
        <p>
          A aceitação dos Termos de Uso é essencial para o uso dos Serviços e da Plataforma e é vinculativa
          para as Partes. Certifique-se de ler, entender e concordar com estes Termos de Uso, caso contrário,
          o <b>Usuário</b> não poderá utilizar os Serviços ou acessar e usar a Plataforma.
        </p>
        <ul>
          <li>
            <p><b>DEFINIÇÕES</b></p>
            <ul>
              <li>
                <p>
                  <b>PLATAFORMA</b>: Conjunto de funcionalidades administrativas e agenciais, criado e mantido pela
                  <b>CLOUD CRM</b> e disponível para acesso, pelo <b>Usuário</b>, exclusivamente pela internet, por
                  meio do endereço eletrônico <a href="https://www.mycloudcoin.com.br">www.mycloudcoin.com.br</a>.
                </p>
              </li>
              <li>
                <p>
                  <b>LOGIN</b>: Endereço de e-mail criado e informado pelo próprio <b>Usuário</b>
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script rel="script">

  export default {
    name: "Terms.vue"
  }

</script>
<style rel="stylesheet" scoped>

  .terms {
    padding: 20px 0 50px 0;
  }

  .terms > .container {
    max-width: 850px;
  }

  .terms h2 {
    margin-bottom: 30px;
    margin-top: 2rem;
    border-bottom: 1px solid #c4c4c4;
    color: #666;
    font-size: 20px;
    font-weight: bold;
    line-height: 130%;
    padding-bottom: 10px;
  }

  .terms p {
    text-align: justify;
  }

  .terms ul {
    margin-left: 20px;
    margin-right: 20px;
  }

  .terms ul ul {
    margin-left: -10px;
    list-style: disc;
  }

  @media only screen and (max-width: 920px){
      .terms{
        overflow-x: hidden;
      }
  }

</style>