<template>
  <div class="support">
    <div class="container">
      <div class="row">
        <h2 class="col text-center">Canais de suporte</h2>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-4" style="margin: 50px 0">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path>
              </svg>
              <span class="channel-label">E-mail</span>
            </div>
            <div class="col-12 col-md-8 d-flex align-items-center">
              <span>
                Envie um e-mail para
                <a href="mailto:luiz@cloudcrm.tech" target="_blank">luiz@cloudcrm.tech</a>
                a partir do e-mail usado para criar sua conta.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script rel="script">

  export default {
    name: "Support.vue"
  }

</script>
<style rel="stylesheet" scoped>

  .support {
    padding: 20px 0 50px 0;
    min-height: 420px;
  }

  .support > .container {
    max-width: 850px;
  }

  .support h2 {
    margin-bottom: 30px;
    margin-top: 2rem;
    color: #666;
    font-size: 24px;
    line-height: 130%;
    padding-bottom: 10px;
  }

  .support svg {
    width: 1em;
    color: #f3c011;
    font-size: 2em;
    margin-right: 15px;
  }

  .support-category {
    margin: 50px 0;
  }

  .support-category span {
    font-size: 1.5rem;
  }

</style>