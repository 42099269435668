const ApiService = {

    baseUrl: null,

    getBaseUrl() {
        return this.baseUrl;
    },

    setBaseUrl(baseUrl) {
        this.baseUrl = baseUrl;
    }

}

export default ApiService;